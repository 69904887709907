import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubscriptionFormMini from './SubscriptionFormMini';

const NeverMissDeals = () => {
  return (
    <Container className='columns'>
      <Row>
        <Col md={6} className="column-left">
          {/* Content for the left column */}
          <SubscriptionFormMini />
        </Col>
        <Col md={6} className="column-right">
          {/* Content for the right column */}
          <br />
          <br />
          <br />
          <h1 style={{ fontSize:"80px" }}>Never miss a deal!</h1>
          <ul>
            <li style={{ fontSize:"20px" }}>Constant update as soon as a deal launches</li>
            <li style={{ fontSize:"20px" }} >Use code PICKLE for the best current deal!</li>
            <li style={{ fontSize:"20px" }}>Easily compare all the deals</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default NeverMissDeals;
