import React, { useState, useLayoutEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Modal } from 'bootstrap'; 
import Banner from "./components/Banner";
import  { Help } from "./components/Help";
import { Resources } from "./components/Resources";
import { Footer } from "./components/Footer";
import SignUpModal from "./components/SignUpModal";
import Affiliates from './components/Affiliates';
import PropComparisons from './components/PropComparisons';
import NeverMissDeals from './components/NeverMissDeals';
import { NavBar } from "./components/NavBar";

function App() {
  const [showModal, setShowModal] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    localStorage.removeItem('isModalClosed');

    const isModalClosed = localStorage.getItem('isModalClosed');

    if (!isModalClosed) {
      const timer = setTimeout(() => {
        setShowModal(true);
        const modalElement = document.getElementById('modalSignin');
        if (modalElement) {
          const modal = new Modal(modalElement);
          modal.show();
        }
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem('isModalClosed', 'true');
  };

  return (
    <div className="App">
      <SignUpModal showModal={showModal} onClose={handleCloseModal} />
      <NavBar />
      <Banner />
      <Help/>
      <Affiliates />
      <Resources />
      <NeverMissDeals />
      <PropComparisons />
      <Footer />
    </div>
  );
}

export default App;
