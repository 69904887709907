import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/SirPickle.jpg";
import navIcon1 from "../assets/img/icons8-twitter-500.svg";
import navIcon2 from "../assets/img/icons8-youtube.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} className="text-center">
            <img src={logo} alt="Logo" className="footer-logo" />
          </Col>
          <Col size={12} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://x.com/SirPickle_"><img src={navIcon1} alt="Twitter" /></a>
              <a href="https://www.youtube.com/@SirPickle_"><img src={navIcon2} alt="YouTube" /></a>
              <a href="https://www.instagram.com/sirpickle__/"><img src={navIcon3} alt="Instagram" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}