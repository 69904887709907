import React, { useState } from 'react';
import PropTable from './PropTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const PropComparisons = () => {
  const [selectedAccountSizes, setSelectedAccountSizes] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const accountSizes = ['$10,000', '$25,000', '$50,000', '$75,000', '$100,000', '$150,000', '$250,000', '$300,000'];
  const firms = ['Apex Trader Funding', 'Elite Trader Funding', 'Topstep', 'TradeDay', 'MyFundedFutures'];
  const types = ['Unrealized Trailing', 'Static', 'EOD'];

  const toggleSelection = (item, setSelectedItems, selectedItems) => {
    const updatedSelection = selectedItems.includes(item)
      ? selectedItems.filter(i => i !== item)
      : [...selectedItems, item];
  
    setSelectedItems(updatedSelection);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected Account Sizes:', selectedAccountSizes);
    console.log('Selected Firm:', selectedFirm);
    console.log('Selected Type:', selectedType);
  };

  const filters = {
    selectedAccountSizes,
    selectedFirm,
    selectedType
  };

  return (
    <div className='prop-comparisons-wrapper' id="propcomparisons">
      <h1 style={{ textAlign: 'center', backgroundColor: 'black'}}>Prop Firm Comparison Tool</h1>
      <div className="prop-container">
        <form onSubmit={handleSubmit}>
          <div className="prop-section">
            <h1>What futures plan do you want to compare?</h1>

            <div>
              <label>Select your firm</label>
              <div className="prop-button-group">
                {firms.map(firm => (
                  <button
                    key={firm}
                    type="button"
                    className={`prop-btn ${selectedFirm.includes(firm) ? 'prop-btn-selected' : ''}`}
                    onClick={() => toggleSelection(firm, setSelectedFirm, selectedFirm)}
                  >
                    {firm}
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label>Select your account size</label>
              <div className="prop-button-group">
                {accountSizes.map(size => (
                  <button
                    key={size}
                    type="button"
                    className={`prop-btn ${selectedAccountSizes.includes(size) ? 'prop-btn-selected' : ''}`}
                    onClick={() => toggleSelection(size, setSelectedAccountSizes, selectedAccountSizes)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
            
            <div>
              <label>Select your drawdown type</label>
              <div className="prop-button-group">
                {types.map(type => (
                  <button
                    key={type}
                    type="button"
                    className={`prop-btn ${selectedType.includes(type) ? 'prop-btn-selected' : ''}`}
                    onClick={() => toggleSelection(type, setSelectedType, selectedType)}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="prop-table-container">
        <PropTable filters={filters}/>
      </div>
    </div>
  );
};

export default PropComparisons;
