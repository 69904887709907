import { Card } from "react-bootstrap";

export const CourseCards = ({ imgUrl, url }) => {
  return (
    <Card className="resources-imgbx">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Card.Img variant="top" src={imgUrl} />
      </a>
    </Card>
  )
}
