import React, { useState } from 'react';
import { db } from '../firebase';
import { ref, push, query, orderByChild, equalTo, get } from 'firebase/database';

const SignUpModal = ({ showModal, onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Check if email already exists
      const emailRef = ref(db, 'subscriptions');
      const emailQuery = query(emailRef, orderByChild('email'), equalTo(email));
      const snapshot = await get(emailQuery);

      if (snapshot.exists()) {
        // Email already exists
        setError('This email is already signed up.');
        return;
      }

      // Add new email to the database
      await push(emailRef, { email, name });
      setSuccess(true);
      localStorage.setItem('signedUp', 'true');
      onClose();

      // Optional: You can add a fade-out class to the success message if needed
      setTimeout(() => {
        document.querySelector('.alert-success')?.classList.add('fade-out');
        setTimeout(() => setSuccess(false), 500); // Hide after fade out
      }, 3000);
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    }
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="modal modal-sheet d-block" tabIndex="-1" role="dialog" id="modalSignin">
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded-4 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h1 className="fw-bold mb-0 fs-2">Sign up for the mailing list👇</h1>
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body p-5 pt-0">
              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control rounded-3"
                    id="floatingName"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label htmlFor="floatingName">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control rounded-3"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <button
                  className="w-100 mb-2 btn btn-lg rounded-3 btn-primary modal-sign-up-btn"
                  type="submit"
                  style={{ backgroundColor: '#8CB65A', borderColor: '#8CB65A', color: 'black' }}
                >
                  Sign up
                </button>

                {success && <div className="alert alert-success mt-3">Thank you for signing up!</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
