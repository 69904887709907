import React from 'react';
import { Table, Button } from 'react-bootstrap';
import '../App.css'

const PropTable = ({ filters }) => {
  const { selectedAccountSizes, selectedFirm, selectedType } = filters;

  const data = [
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$25,000", evalPrice: "$147", priceNormalDiscount: "$29", activationFeeMonthly: "$85", activationFeeOneTime: "$130", drawdown: "$1,500", priceTarget: "$1,500", ptToDdRatio: "1.0", contracts: "4 (40 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$50,000", evalPrice: "$167", priceNormalDiscount: "$33", activationFeeMonthly: "$85", activationFeeOneTime: "$140", drawdown: "$2,500", priceTarget: "$3,000", ptToDdRatio: "1.2", contracts: "10 (100 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$75,000", evalPrice: "$187", priceNormalDiscount: "$37", activationFeeMonthly: "$85", activationFeeOneTime: "$180", drawdown: "$2,750", priceTarget: "$4,250", ptToDdRatio: "1.5", contracts: "12 (120 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$100,000", evalPrice: "$207", priceNormalDiscount: "$41", activationFeeMonthly: "$85", activationFeeOneTime: "$220", drawdown: "$3,000", priceTarget: "$6,000", ptToDdRatio: "2.0", contracts: "14 (140 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$150,000", evalPrice: "$297", priceNormalDiscount: "$59", activationFeeMonthly: "$85", activationFeeOneTime: "$260", drawdown: "$5,000", priceTarget: "$9,000", ptToDdRatio: "1.8", contracts: "17 (170 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$250,000", evalPrice: "$517", priceNormalDiscount: "$103", activationFeeMonthly: "$85", activationFeeOneTime: "$300", drawdown: "$6,500", priceTarget: "$15,000", ptToDdRatio: "2.3", contracts: "27 (270 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Unrealized Trailing", accountSize: "$300,000", evalPrice: "$657", priceNormalDiscount: "$131", activationFeeMonthly: "$85", activationFeeOneTime: "$340", drawdown: "$7,500", priceTarget: "$20,000", ptToDdRatio: "2.7", contracts: "35 (350 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Rithmic)", type: "Static", accountSize: "$100,000", evalPrice: "$137", priceNormalDiscount: "$27", activationFeeMonthly: "$85", activationFeeOneTime: "$220", drawdown: "$625", priceTarget: "$2,000", ptToDdRatio: "3.2", contracts: "2 (20 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$25,000", evalPrice: "$167", priceNormalDiscount: "$33", activationFeeMonthly: "$105", activationFeeOneTime: "$138", drawdown: "$1,500", priceTarget: "$1,500", ptToDdRatio: "1.0", contracts: "4 (40 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$50,000", evalPrice: "$187", priceNormalDiscount: "$37", activationFeeMonthly: "$105", activationFeeOneTime: "$142", drawdown: "$2,500", priceTarget: "$3,000", ptToDdRatio: "1.2", contracts: "10 (100 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$75,000", evalPrice: "$207", priceNormalDiscount: "$41", activationFeeMonthly: "$105", activationFeeOneTime: "$146", drawdown: "$2,750", priceTarget: "$4,250", ptToDdRatio: "1.5", contracts: "12 (120 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$100,000", evalPrice: "$227", priceNormalDiscount: "$45", activationFeeMonthly: "$105", activationFeeOneTime: "$150", drawdown: "$3,000", priceTarget: "$6,000", ptToDdRatio: "2.0", contracts: "14 (140 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$150,000", evalPrice: "$317", priceNormalDiscount: "$40", activationFeeMonthly: "$105", activationFeeOneTime: "$145", drawdown: "$5,000", priceTarget: "$9,000", ptToDdRatio: "1.8", contracts: "17 (170 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$250,000", evalPrice: "$537", priceNormalDiscount: "$40", activationFeeMonthly: "$105", activationFeeOneTime: "$145", drawdown: "$6,500", priceTarget: "$15,000", ptToDdRatio: "2.3", contracts: "27 (270 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Unrealized Trailing", accountSize: "$300,000", evalPrice: "$677", priceNormalDiscount: "$40", activationFeeMonthly: "$105", activationFeeOneTime: "$145", drawdown: "$7,500", priceTarget: "$20,000", ptToDdRatio: "2.7", contracts: "35 (350 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Apex Trader Funding <br />(Tradovate)", type: "Static", accountSize: "$100,000", evalPrice: "$157", priceNormalDiscount: "$31", activationFeeMonthly: "$105", activationFeeOneTime: "$136", drawdown: "$625", priceTarget: "$2,000", ptToDdRatio: "3.2", contracts: "2 (20 Micros)", signUpLink: "https://bit.ly/ATFPICKLE" },
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Unrealized Trailing", accountSize: "$50,000", evalPrice: "$165", priceNormalDiscount: "$50", activationFeeMonthly: "$80", activationFeeOneTime: "$130", drawdown: "$2,000", priceTarget: "$3,000", ptToDdRatio: "1.5", contracts: "8 (80 Micros)", signUpLink: "https://tinyurl.com/Picklesir"},
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Unrealized Trailing", accountSize: "$100,000", evalPrice: "$205", priceNormalDiscount: "$62", activationFeeMonthly: "$80", activationFeeOneTime: "$142", drawdown: "$3,000", priceTarget: "$6,000", ptToDdRatio: "2.0", contracts: "14 (140 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Unrealized Trailing", accountSize: "$150,000", evalPrice: "$295", priceNormalDiscount: "$89", activationFeeMonthly: "$80", activationFeeOneTime: "$169", drawdown: "$5,000", priceTarget: "$9,000", ptToDdRatio: "1.8", contracts: "18 (180 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Unrealized Trailing", accountSize: "$250,000", evalPrice: "$515", priceNormalDiscount: "$155", activationFeeMonthly: "$80", activationFeeOneTime: "$235", drawdown: "$6,000", priceTarget: "$15,000", ptToDdRatio: "2.5", contracts: "24 (240 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Unrealized Trailing", accountSize: "$300,000", evalPrice: "$655", priceNormalDiscount: "$197", activationFeeMonthly: "$80", activationFeeOneTime: "$277", drawdown: "$7,500", priceTarget: "$20,000", ptToDdRatio: "2.7", contracts: "30 (300 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Static", accountSize: "$100,000", evalPrice: "$135", priceNormalDiscount: "$41", activationFeeMonthly: "$80", activationFeeOneTime: "$121", drawdown: "$525", priceTarget: "$2,000", ptToDdRatio: "3.2", contracts: "2 (20 Micros)", signUpLink: "https://tinyurl.com/Picklesir"},
    { company: "Elite Trader Funding <br/>(1-Step)", type: "Static", accountSize: "$150,000", evalPrice: "$300", priceNormalDiscount: "$90", activationFeeMonthly: "$80", activationFeeOneTime: "$170", drawdown: "$1,250", priceTarget: "$4,000", ptToDdRatio: "3.2", contracts: "4 (40 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(Fast Track)", type: "Unrealized Trailing", accountSize: "$100,000", evalPrice: "$75", priceNormalDiscount: "$75", activationFeeMonthly: "$80", activationFeeOneTime: "$155", drawdown: "$3,000", priceTarget: "$6,000", ptToDdRatio: "2.0", contracts: "14 (140 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(Fast Track)", type: "Unrealized Trailing", accountSize: "$250,000", evalPrice: "$175",priceNormalDiscount: "$175", activationFeeMonthly: "$80", activationFeeOneTime: "$255", drawdown: "$6,000", priceTarget: "$15,000", ptToDdRatio: "2.5", contracts: "24 (240 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding", type: "EOD", accountSize: "$50,000", evalPrice: "$295", priceNormalDiscount: "$89", activationFeeMonthly: "$80", activationFeeOneTime: "$169", drawdown: "$2,000", priceTarget: "$3,000", ptToDdRatio: "1.5", contracts: "8 (80 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding", type: "EOD", accountSize: "$100,000", evalPrice: "$430", priceNormalDiscount: "$129", activationFeeMonthly: "$80", activationFeeOneTime: "$209", drawdown: "$3,500", priceTarget: "$6,000", ptToDdRatio: "1.7", contracts: "14 (140 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding", type: "EOD", accountSize: "$150,000", evalPrice: "$605", priceNormalDiscount: "$182", activationFeeMonthly: "$80", activationFeeOneTime: "$262", drawdown: "$4,500", priceTarget: "$9,000", ptToDdRatio: "2.0", contracts: "18 (180 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding", type: "EOD", accountSize: "$250,000", evalPrice: "$745", priceNormalDiscount: "$224", activationFeeMonthly: "$80", activationFeeOneTime: "$304", drawdown: "$7,000", priceTarget: "$15,000", ptToDdRatio: "2.1", contracts: "24 (240 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Elite Trader Funding <br/>(Diamond Hands)", type: "EOD", accountSize: "$100,000", evalPrice: "$365", priceNormalDiscount: "$110", activationFeeMonthly: "$80", activationFeeOneTime: "$190", drawdown: "$5,000", priceTarget: "$5,000", ptToDdRatio: "1.0", contracts: "2 (20 Micros)", signUpLink: "https://tinyurl.com/Picklesir" },
    { company: "Topstep", type: "EOD", accountSize: "$50,000", evalPrice: "$165", priceNormalDiscount: "$49", activationFeeMonthly: "", activationFeeOneTime: "$149", resetPrice: "$49", drawdown: "$2,000", priceTarget: "$3,000", ptToDdRatio: "1.5", contracts: "5 (50 Micros)", signUpLink: "https://www.topstep.com/" },
    { company: "Topstep", type: "EOD", accountSize: "$100,000", evalPrice: "$325", priceNormalDiscount: "$99", activationFeeMonthly: "", activationFeeOneTime: "$149", resetPrice: "$99", drawdown: "$3,000", priceTarget: "$6,000", ptToDdRatio: "2.0", contracts: "10 (10 Micros)", signUpLink: "https://www.topstep.com/" },
    { company: "Topstep", type: "EOD", accountSize: "$150,000", evalPrice: "$375", priceNormalDiscount: "$149", activationFeeMonthly: "", activationFeeOneTime: "$149", resetPrice: "$149", drawdown: "$4,500", priceTarget: "$9,000", ptToDdRatio: "2.0", contracts: "15 (15 Micros)", signUpLink: "https://www.topstep.com/" },
    { company: "TradeDay", type: "EOD", accountSize: "$10,000", evalPrice: "$99", priceNormalDiscount: "$79", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$1,000", priceTarget: "$1,000", ptToDdRatio: "1.0", contracts: "1 (10 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "TradeDay", type: "EOD", accountSize: "$25,000", evalPrice: "$125", priceNormalDiscount: "$100", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$1,500", priceTarget: "$1,500", ptToDdRatio: "1.0", contracts: "2 (20 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "TradeDay", type: "EOD", accountSize: "$50,000", evalPrice: "$165", priceNormalDiscount: "$132", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$2,000", priceTarget: "$2,500", ptToDdRatio: "1.3", contracts: "5 (50 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "TradeDay", type: "EOD", accountSize: "$100,000", evalPrice: "$275", priceNormalDiscount: "$220", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$3,000", priceTarget: "$5,000", ptToDdRatio: "1.7", contracts: "10 (100 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "TradeDay", type: "EOD", accountSize: "$150,000", evalPrice: "$350", priceNormalDiscount: "$280", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$4,000", priceTarget: "$7,500", ptToDdRatio: "1.9", contracts: "15 (150 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "TradeDay", type: "EOD", accountSize: "$250,000", evalPrice: "$750", priceNormalDiscount: "$600", activationFeeMonthly: "", activationFeeOneTime: "$139", resetPrice: "$99", drawdown: "$5,000", priceTarget: "$12,000", ptToDdRatio: "2.4", contracts: "25 (250 Micros)", signUpLink: "https://members.tradeday.com/?aff=TD_SirPickle" },
    { company: "MyFundedFutures <br />(starter)", type: "EOD", accountSize: "$50,000", evalPrice: "$80", priceNormalDiscount: "", activationFeeMonthly: "", activationFeeOneTime: "$149", drawdown: "$2,500", priceTarget: "$3,000", ptToDdRatio: "1.2", contracts: "3 (30 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965" },
    { company: "MyFundedFutures <br />(starter)", type: "EOD", accountSize: "$100,000", evalPrice: "$150", priceNormalDiscount: "", activationFeeMonthly: "", activationFeeOneTime: "$149", drawdown: "$3,500", priceTarget: "$6,000", ptToDdRatio: "1.7", contracts: "6 (60 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965" },
    { company: "MyFundedFutures <br />(starter)", type: "EOD", accountSize: "$150,000", evalPrice: "$220", priceNormalDiscount: "", activationFeeMonthly: "", activationFeeOneTime: "$149", drawdown: "$5,000", priceTarget: "$9,000", ptToDdRatio: "1.8", contracts: "9 (90 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965" },
    { company: "MyFundedFutures <br />(expert)", type: "EOD", accountSize: "$50,000", evalPrice: "$165", priceNormalDiscount: "$165", activationFeeMonthly: "", activationFeeOneTime: "$165", drawdown: "$2,000", priceTarget: "$4,000", ptToDdRatio: "2.0", contracts: "5 (5 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965" },
    { company: "MyFundedFutures <br />(expert)", type: "EOD", accountSize: "$100,000", evalPrice: "$265", priceNormalDiscount: "$265", activationFeeMonthly: "", activationFeeOneTime: "$265", drawdown: "$3,000", priceTarget: "$8,000", ptToDdRatio: "2.7", contracts: "10 (100 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965" },
    { company: "MyFundedFutures <br />(expert)", type: "EOD", accountSize: "$150,000", evalPrice: "$375", priceNormalDiscount: "$375", activationFeeMonthly: "", activationFeeOneTime: "$375", drawdown: "$4,500", priceTarget: "$12,000", ptToDdRatio: "2.7", contracts: "15 (150 Micros)", signUpLink: "https://myfundedfutures.com/?ref=965"},
  ];

  const filteredData = data.filter(row => {
    const matchAccountSize = selectedAccountSizes.length === 0 || selectedAccountSizes.includes(row.accountSize);
    const matchType = selectedType.length === 0 || selectedType.includes(row.type);

    let matchFirm = selectedFirm.length === 0 || selectedFirm.includes(row.company);

    if (selectedFirm.includes("Apex Trader Funding")) {
      const matchApexTraderFunding = row.company === "Apex Trader Funding <br />(Rithmic)" || row.company === "Apex Trader Funding <br />(Tradovate)";
      matchFirm = matchFirm || matchApexTraderFunding;
    }
    if (selectedFirm.includes("Elite Trader Funding")) {
      const matchEliteTraderFunding = row.company === "Elite Trader Funding <br/>(1-Step)" || row.company === "Elite Trader Funding <br/>(Fast Track)" || row.company === "Elite Trader Funding" || row.company === "Elite Trader Funding <br/>(Diamond Hands)";
      matchFirm = matchFirm || matchEliteTraderFunding;
    }
    if (selectedFirm.includes("MyFundedFutures")) {
      const matchMyFundedFutures = row.company === "MyFundedFutures <br />(starter)" || row.company === "MyFundedFutures <br />(expert)";
      matchFirm = matchFirm || matchMyFundedFutures;
    }

    return matchAccountSize && matchFirm && matchType;
  }); 

  const buttonStyle = {
    backgroundColor: '#8CB65A',
    borderColor: '#8CB65A',
    color: 'white'
  };

  const buttonHoverStyle = {
    backgroundColor: 'black',
    borderColor: 'black',
    color: 'white'
  };
    
  return (
    <div>
      <div className="affiliates-table-wrapper">
        <div className="deals-at-a-glance">
          <Table striped bordered hover className="affiliates-table">
            <thead>
              <tr>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Company</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Account <br />Size</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Drawdown <br />Type</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Eval <br />Price</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Discounted <br />Price</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Activation <br />Fee <br />(Monthly)</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Activation <br />Fee <br />(One-time)</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Max <br />Drawdown</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Profit <br />Target</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Max <br />Contracts</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Profit<br />to<br />Drawdown <br />Ratio</th>
                <th style={{ backgroundColor: '#6A7D4E', color: 'white' }}>Sign-up<br />Link</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={index}>
                  <td dangerouslySetInnerHTML={{ __html: row.company }}></td>
                  <td>{row.accountSize}</td>
                  <td>{row.type}</td>
                  <td>{row.evalPrice}</td>
                  <td>{row.priceNormalDiscount}</td>
                  <td>{row.activationFeeMonthly}</td>
                  <td>{row.activationFeeOneTime}</td>
                  <td>{row.drawdown}</td>
                  <td>{row.priceTarget}</td>
                  <td>{row.contracts}</td>
                  <td>{row.ptToDdRatio}</td>
                  <td><Button 
                    href={row.signUpLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={buttonStyle}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonHoverStyle.borderColor;
                      e.currentTarget.style.color = buttonHoverStyle.color;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                      e.currentTarget.style.borderColor = buttonStyle.borderColor;
                      e.currentTarget.style.color = buttonStyle.color;
                    }}
                  >
                    Sign Up
                  </Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PropTable;
