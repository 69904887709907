import React, { useState } from 'react';
import { db } from '../firebase';
import { ref, push, query, orderByChild, equalTo, get } from 'firebase/database';

const SubscriptionFormMini = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);

    try {
      // Check if email already exists
      const emailRef = ref(db, 'subscriptions');
      const emailQuery = query(emailRef, orderByChild('email'), equalTo(email));
      const snapshot = await get(emailQuery);

      if (snapshot.exists()) {
        // Email already exists
        setError('This email is already subscribed.');
        setIsClicked(false);
        return;
      }

      // Add new subscription
      await push(emailRef, { name, email });
      setSuccess(true);
      setName('');
      setEmail('');

      // Add a fade-out class to the success message
      setTimeout(() => {
        document.querySelector('.alert-success')?.classList.add('fade-out');
        setTimeout(() => setSuccess(false), 500); // Hide after fade out
      }, 3000);
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    } finally {
      setIsClicked(false);
    }
  };

  return (
    <div className="subscription-form-container">
      <div className="subscription-form border p-3 rounded">
        <h2 style={{ fontWeight: 'bold' }}>Get free email alerts when a new deal is available.</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={`btn w-100 ${isClicked ? 'btn-black' : 'btn-primary'}`}>
            Subscribe
          </button>
          {success && (
            <div className="alert alert-success mt-3">Thank you for signing up!</div>
          )}
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default SubscriptionFormMini;
