import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";

export const Help = () => {

  return (
    <section className="help" id="help">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="help-bx">
                        <h2>FREE Resources!👇</h2>
                        <br/>
                        <p>Start your journey to profitability!</p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Background Design" />
    </section>
  );
};
